export const environment = {
  production: false,

  hash: '8hDwccSyb2',

  uris: {
    // self: 'http://localhost:8100',
    // selfprod: 'https://ggzc-levels1.gamebus.eu/',
    // assets: 'https://studio.gamebus.eu/assets/static',
    // api:'http://localhost:8024/v2',
    // airbridge: 'http://localhost:3000',
    
    self: 'https://samen3.ggzond.fun/',
    selfprod: 'https://samen3.ggzond.fun/',
    assets: 'https://studio.gamebus.eu/assets/static',
    api: 'https://api3-new.gamebus.eu/v2',
    airbridge: 'https://airbridge-api.gamebus.eu',

  },

  client: {
    id: 'Gamebus_Studio_Levels1',
    secret: 'f8#fI!*MDQEPs9@!#FD0asfh213',
    dataprovider: 'Gamebus_Studio_Levels1',
  },

  languages: ['nl', 'en'], // Default language first

  // Default consent items
  consent: [
    { tk: 'name-visible', required: true },
    { tk: 'terms-conditions', required: true, link: 'https://blog.gamebus.eu/?page_id=1066' }
  ],

};
